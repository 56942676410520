@import url("https://fonts.googleapis.com/css2?family=Almarai&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiri:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lemonada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Scheherazade&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lateef&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Harmattan&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mirza&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rakkas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Katibeh&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jomhuria&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vibes&display=swap");

button:focus {
  outline: none;
}
button:active {
  outline: none;
}

.TopHomePage {
  background-image: url("/public/images/TopHomePage.png");
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  /* background-position: 100% 100%; */
  background-size: cover;
  background-position: center;
}

body {
  font-family: "A Jannat LT";
  background-image: url(/public/images/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* @media screen and (min-width: 1024px) and (max-width: 1280px) {
  html {
    font-size: 20px;
  }
}
@media screen and (min-width: 1536px) and (max-width: 1920px) {
  html {
    font-size: 22px;
  }
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 32px;
  }
} */

.container {
  margin: 0 auto;
  height: auto;
}

.container-stretch {
  position: absolute;
  left: 0;
  right: 0;
}

.overview-sl {
  background-image: url("/public/images/female-african-american.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.overview {
  background-image: url("/public/images/female-african-american.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
}
.background-packs {
  background-image: url("https://image.freepik.com/free-photo/decorative-rough-background_24972-295.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.card-container {
  max-width: 72rem;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 23fr;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 7rem;
  align-items: center;
  justify-items: center;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  top: 60px;
}

/* Show the dropdown menu on hover */
.dropdown .dropdownContent {
  display: block;
}

.picfit {
  object-fit: cover;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContentBlog {
  bottom: -50px;
}

/* Show the dropdown menu on hover */
.dropdownBlog .dropdownContentBlog {
  display: block;
}

.whatsapp {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  .whatsapp {
    width: 50px;
    height: 50px;
    bottom: 10px;
    right: 10px;
  }
}

.swiper-button-next {
  --swiper-theme-color: #492b83;
}
.swiper-button-prev {
  --swiper-theme-color: #492b83;
}
.swiper-pagination-bullet {
  --swiper-theme-color: #492b83;
}

/* Change tailwind after (start)*/
.FormGroup {
  padding: 0;
  border-style: none;
  background-color: #d29e83;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #d29e83;
  border-radius: 4px;
  color: #5c5c5c;
}

.FormRow {
  color: #5c5c5c;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.stripe_button {
  display: block;
  font-size: 16px;
  width: 100%;
  height: 50px;
  margin-top: 1rem;
  background-color: #5c5c5c;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #5c5c5c;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}
.stripe_button:active {
  background-color: #5c5c5c;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #5c5c5c;
  transform: scale(0.99);
}

.FormGroup h1,
.FormGroup h3 {
  text-align: center;
}
